<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel
      v-for="(group, index) in groups"
      :key="index"
      class="mb-4"
    >
      <v-expansion-panel-header
        hide-actions
        v-slot="{ open }"
        :style="headerStyles(index)"
        :class="headerClass(index)"
      >
        <v-row align="center">
          <v-col class="shrink">
            <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
            <v-icon v-else>mdi-plus-thick</v-icon>
          </v-col>
          <v-col :class="(open ? 'white--text' : '') + ' font-weight-medium'">
            {{ group.name }}
          </v-col>
          <v-col
            class="shrink px-0"
            v-if="open && group.level < 3 && $store.getters.isWebAdmin"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  text
                  small
                  color="white"
                  v-on="on"
                  @click.stop
                  @click="showNewGroupDetails(group)"
                >
                  <v-icon> mdi-folder-plus-outline </v-icon>
                </v-btn>
              </template>
              <span>Add Folder</span>
            </v-tooltip>
          </v-col>
          <v-col class="shrink px-0" v-if="$store.getters.isWebAdmin">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  text
                  small
                  :color="open ? 'white' : 'msaBlue'"
                  v-on="on"
                  @click.stop
                  @click="$emit('showGroupDetails', group)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
          <v-col class="shrink px-0" v-if="$store.getters.isWebAdmin">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  text
                  small
                  color="red"
                  v-on="on"
                  @click.stop
                  @click="deleteGroup(group.id)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-4">
        <v-row>
          <v-col
            cols="12"
            align="right"
            v-if="
              group.level < 3 &&
              group.groups.length > 1 &&
              canChangeOrders &&
              $store.getters.isWebAdmin
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  class="msaBlue white--text"
                  v-on="on"
                  @click="$emit('orderFolders', group.groups)"
                >
                  <v-icon> mdi-folder-arrow-up-down-outline </v-icon>
                </v-btn>
              </template>
              <span>Order Folders</span>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            v-if="group.level < 3 && group.groups.length"
            class="pb-0"
          >
            <PrivateDocumentGroupsSimple
              :groups="group.groups"
              @showGroupDetails="$emit('showGroupDetails', $event)"
              @deleteGroup="$emit('deleteGroup', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            align="right"
            v-if="
              group.documents.length > 1 &&
              canChangeOrders &&
              $store.getters.isWebAdmin
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-blur
                  class="msaBlue white--text"
                  v-on="on"
                  @click="$emit('orderDocuments', group.documents)"
                >
                  <v-icon> mdi-file-arrow-up-down-outline </v-icon>
                </v-btn>
              </template>
              <span>Order Files</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <div
          v-if="
            group.level < 3 &&
            group.groups.length == 0 &&
            group.documents.length == 0
          "
          class="text-center mt-6"
        >
          (Empty Folder)
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PrivateDocumentGroupsSimple from '@/components/PrivateDocumentLibrary/PrivateDocumentGroupsSimple.vue';

export default {
  name: 'PrivateDocumentGroupsSimple',
  components: {
    PrivateDocumentGroupsSimple,
  },
  props: {
    groups: {
      type: Array,
    },
  },
  data() {
    return {
      expanded: undefined,
      canChangeOrders: false,
    };
  },
  computed: {
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    headerClass() {
      return (index) => (index == this.expanded ? 'msaBlue white--text' : '');
    },
  },

  methods: {
    showNewGroupDetails(parentGroup) {
      const group = {
        id: 0,
        level: parentGroup.level + 1,
        name: '',
        parentPrivateLibraryGroupId: parentGroup.id,
      };
      this.$emit('showGroupDetails', group);
    },
    deleteGroup(id) {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this folder? All folders and files under this folder will be deleted.',
        () => {
          this.$emit('deleteGroup', id);
        },
        () => false,
        'Confirm',
        'Cancel',
      );
    },
  },
};
</script>

<style></style>
