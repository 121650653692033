<template>
  <v-dialog v-model="dialog" width="1000px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="documents.length"
        :value="documents.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px`"
      >
        <v-btn
          ref="selectionBtn"
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
          @click="show"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected Files
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Choose to publish files imported to My Library </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row>
          <v-col class="grow">
            <CustomizedAutoComplete
              :value="globalGroup"
              :items="plainGroups"
              hide-details
              label="Select a global folder"
              @change="
                globalGroup = $event;
                globalGroupChanged();
              "
            />
          </v-col>
          <v-col class="shrink">
            <PrivateDocumentLibraryGroupManageDialog
              @folderChange="onFolderChange"
              @groupDelete="onGroupDelete"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightGrey">
                    <tr>
                      <th class="text-left">Selected Files</th>
                      <th>Folder</th>
                      <th class="text-right">
                        <v-row no-gutters align="center">
                          <v-col class="grow" align="right">
                            <PublishButtonGroup
                              :is-published="globalPublish"
                              @click="
                                globalPublish = $event;
                                handlePublishAll($event);
                              "
                              :key="reloadGroupPublish"
                            />
                          </v-col>
                          <v-col class="shrink ml-2">
                            <v-sheet width="36"></v-sheet>
                          </v-col>
                        </v-row>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in edited" :key="item.id">
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        <CustomizedAutoComplete
                          :value="item.targetGroupId"
                          :items="plainGroups"
                          hide-details
                          label="Select a folder in My Library"
                          @change="
                            item.targetGroupId = $event;
                            individualGroupChanged();
                          "
                        />
                      </td>
                      <td align="right" :style="{ width: '200px' }">
                        <v-row no-gutters align="center">
                          <v-col class="grow" align="right">
                            <PublishButtonGroup
                              :is-published="item.targetPublish"
                              @click="item.targetPublish = $event"
                            />
                          </v-col>

                          <v-col class="shrink ml-2">
                            <v-btn icon color="red" @click="unselect(item)">
                              <v-icon> mdi-minus-circle-outline </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="cancel"> cancel </v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="confirm"
          :disabled="disableConfirm"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrivateDocumentLibraryGroupManageDialog from '@/components/PrivateDocumentLibrary/PrivateDocumentLibraryGroupManageDialog.vue';
import PublishButtonGroup from '@/components/PublishButtonGroup';
export default {
  name: 'PublicDocumentLibaraySelectionModal',
  components: {
    PrivateDocumentLibraryGroupManageDialog,
    PublishButtonGroup,
  },
  props: {
    documents: {
      type: Array,
    },
  },
  watch: {
    edited: {
      deep: true,
      handler(val) {
        if (val.every((d) => d.targetPublish == 1)) {
          this.globalPublish = 1;
          return;
        }
        if (val.every((d) => d.targetPublish == 0)) {
          this.globalPublish = 0;
          return;
        }
        this.globalPublish = null;
      },
    },
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      privateGroups: [],
      plainGroups: [],
      globalGroup: null,
      edited: [],
      selectedGroup: {
        id: 0,
        level: 1,
        name: '',
        parentPrivateLibraryGroupId: 0,
      },
      globalPublish: 0,
      reloadGroupPublish: true,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    publishBtnText() {
      return this.edited.every((d) => d.targetPublish)
        ? 'Unpublish All'
        : 'Publish All';
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 249 / 2;
    },
    disableConfirm() {
      return this.edited.some((d) => d.targetGroupId == '');
    },
  },
  methods: {
    onFolderChange() {
      this.getPlainGroups();
    },
    onGroupDelete(groupId) {
      this.updateDocumentOnGroupDelete(groupId);
    },
    updateDocumentOnGroupDelete(groupId) {
      this.edited.map((doc) => {
        if (doc.targetGroupId == groupId) {
          doc.targetGroupId = '';
        }
      });
    },
    confirm() {
      const params = {
        loaderText: 'saving...',
        documents: this.edited,
      };

      const url = 'add-public-documents-to-private-library?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          if (
            response.data.missingFiles.length ||
            response.data.emptySpreadsheets.length
          ) {
            this.handleImportErrors(response.data);
          }
          this.$emit('confirmed');
          this.edited = [];
          this.globalGroup = null;
          this.dialog = false;
        })
        .catch((error) => error);
    },
    handleImportErrors(failedDocuments) {
      const missingFileDocumentIds = failedDocuments.missingFiles;
      const emptySpreadsheetDocumentIds = failedDocuments.emptySpreadsheets;
      const missingFileDocumentNames = this.documents
        .filter((d) => missingFileDocumentIds.includes(d.id))
        .map((d) => d.name);
      const emptySpreadsheetDocumentNames = this.documents
        .filter((d) => emptySpreadsheetDocumentIds.includes(d.id))
        .map((d) => d.name);
      let message = '';
      if (missingFileDocumentNames.length > 0) {
        message +=
          'The following documents are not imported due to file missing on server. <br>' +
          missingFileDocumentNames.join('<br>');
        message += '<br>';
      }
      if (emptySpreadsheetDocumentNames.length > 0) {
        message +=
          'The following documents are not published due to empty spreadsheet. <br>' +
          emptySpreadsheetDocumentNames.join('<br>');
      }
      this.$root.showMessage('Warning', message, () => {}, null, 'Ok', '');
    },
    handlePublishAll(value) {
      this.edited.forEach((d) => (d.targetPublish = value));
    },
    unselect(doc) {
      this.$emit('onUnselect', doc);

      const index = this.edited.findIndex((d) => d.id == doc.id);
      this.edited.splice(index, 1);

      if (this.edited.length == 0) {
        this.close();
      }
    },
    close() {
      this.dialog = false;
      this.$emit('closed', this.edited);
    },
    show() {
      this.edited = JSON.parse(JSON.stringify(this.documents));
      if (!this.loaded) {
        this.getPlainGroups();
      }
    },
    getPlainGroups() {
      this.$axios
        .post('get-private-library-groups-for-select?format=json', {
          loaderText: 'Loading...',
          userType: this.$constants.USER_TYPES.WEB_ADMIN,
        })
        .then((response) => {
          this.plainGroups = response.data;
        });
    },
    globalGroupChanged() {
      this.edited.forEach((d) => (d.targetGroupId = this.globalGroup));
    },
    individualGroupChanged() {
      this.globalGroup = null;
      this.reloadGroupPublish = !this.reloadGroupPublish;
    },
    cancel() {
      this.edited = [];
      this.$emit('cancel');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
