<template>
  <v-data-table
    v-model="thisSelected"
    :headers="headers"
    :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
    :items="documents"
    :server-items-length="documentCount"
    :loading="loading"
    show-select
    item-key="id"
    must-sort
    checkbox-color="msaBlue"
    class="public-document-list"
    :item-class="rowClass"
    :options.sync="options"
    @item-selected="onSelect($event)"
    @toggle-select-all="onSelect($event)"
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-row no-gutters>
        <v-col cols="12">
          <span
            @click="downloadDocument(item)"
            class="msaBlue--text"
            :style="{ cursor: 'pointer' }"
          >
            {{ item.name }}
          </span>
        </v-col>
        <v-col class="font-italic text-caption" v-if="item.addDate != ''">
          This document has been added to My Library on
          {{ item.addDate | filterAsLocalDate }}
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      {{ item.createdAt | filterAsLocalDate }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'PublicDocumentLibraryDocuments',
  props: {
    filters: {
      type: Object,
    },
    groupId: {
      type: Number,
    },
    selected: {
      type: Array,
    },
  },
  watch: {
    options: {
      handler() {
        this.getPublicLibraryDocuments();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.getPublicLibraryDocuments();
      },
      deep: true,
    },
    selected() {
      this.checkSelected();
    },
  },
  data() {
    return {
      documents: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Created Date',
          value: 'createdAt',
          width: '130px',
          class: 'lightGrey',
        },
      ],
      loading: false,
      thisSelected: [],
      options: {
        sortBy: [''],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      documentCount: 0,
    };
  },
  methods: {
    getPublicLibraryDocuments() {
      this.loading = true;
      const params = {
        filters: this.filters,
        groupId: this.groupId,
        options: this.options,
      };

      const url = 'get-public-library-documents-by-group?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.documents = response.data.documents;
          this.documentCount = response.data.count;
          // bit to boolean
          this.documents.forEach(
            (doc) => (doc.isSelectable = !!doc.isSelectable),
          );
          this.checkSelected();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    checkSelected() {
      this.thisSelected = this.documents.filter((document) =>
        this.selected.some((selected) => selected.id == document.id),
      );
    },
    rowClass(item) {
      if (item.isSelectable) {
        return '';
      }
      return 'msaGreen';
    },
    downloadDocument(doc) {
      const params = {
        id: doc.id,
        loaderText: 'loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-public-library-document?format=json', params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', doc.originalFilename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$root.showMessage(
              'File not found',
              'File does not exist.',
              () => false,
              null,
              'OK',
            );
          } else {
            return error;
          }
        });
    },
    onSelect(event) {
      if (event.item != undefined) {
        this.onSelectOne(event);
      }

      // Handle event when user uses SHIFT to select multiple items
      if (event.currentItem != undefined) {
        event.item = event.currentItem;
        this.onSelectOne(event);
      }

      if (event.items != undefined) {
        this.onSelectAll(event);
      }
    },
    onSelectOne(event) {
      this.$emit('onSelect', {
        docs: [event.item],
        value: event.value,
      });
    },
    onSelectAll(event) {
      if (JSON.stringify(event.items) == JSON.stringify(this.thisSelected)) {
        event.value = false;
      }
      if (event.value) {
        this.thisSelected = event.items.filter((i) => i.addDate == '');
      } else {
        this.$nextTick(() => {
          this.thisSelected = [];
        });
      }

      this.$emit('onSelect', {
        docs: this.thisSelected,
        value: event.value,
      });
    },
  },
  mounted() {},
};
</script>

<style>
.public-document-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
