<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          <v-row>
            <v-col class="grow"> Filters </v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="close()"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12"> Document Name </v-col>
            <v-col>
              <v-text-field
                v-model="filters.search"
                outlined
                hide-details
                placeholder="Enter document name"
                dense
                clearable
                @click:clear="filters.search = ''"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12"> Document Folder </v-col>
            <v-col cols="12">
              <CustomizedAutoComplete
                :value="filters.groupIds"
                multiple
                label="Select a folder"
                hide-details
                :items="groups"
                clearable
                deletable-chips
                @change="filters.groupIds = $event"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="clear()"> clear </v-btn>
          <v-btn class="msaBlue white--text" @click="apply()"> apply </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="isFilterApplied ? 'orange' : 'msaBlue'"
          dark
          v-blur
          v-bind="attrs"
          v-on="on"
          @click="show"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>
      <span>Filter</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'PublicDocumentLibraryFilter',
  data() {
    return {
      isLoading: false,
      dialog: false,
      groups: [],
      isFilterApplied: false,
      filters: {
        search: '',
        groupIds: [],
      },
      original: {
        search: '',
        groupIds: [],
      },
    };
  },
  methods: {
    loadDependencies() {
      this.isLoading = true;
      return this.$axios
        .post('get-public-library-groups-for-filter?format=json', {})
        .then((response) => {
          this.isLoading = false;
          this.groups = response.data;
        });
    },
    setFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.updateFilterButtonState();
    },
    updateFilterButtonState() {
      this.isFilterApplied =
        JSON.stringify(this.filters) !=
        JSON.stringify({ search: '', groupIds: [] });
    },
    close() {
      this.filters = { ...this.orginal };
      this.dialog = false;
    },
    show() {
      this.orginal = { ...this.filters };
      this.dialog = true;
    },
    clear() {
      this.filters = { search: '', groupIds: [] };
      this.apply();
    },
    apply() {
      this.filters = this.$helpers.fixFilterSpaces(
        { search: '', groupIds: [] },
        this.filters,
      );
      this.updateFilterButtonState();
      this.$emit('applyFilters', this.filters);
      this.dialog = false;
    },
  },
  mounted() {
    this.loadDependencies();
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
