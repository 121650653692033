<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <v-card>
      <v-card-title class="msaBlue white--text">
        {{ isNew ? 'Add New Folder' : 'Edit Folder' }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="mt-4 font-weight-bold"> Name: </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-text-field
              ref="groupName"
              class="py-0"
              counter
              maxlength="1000"
              outlined
              v-model="name"
              :rules="rules"
              :error-messages="errorMessages"
              data-testid="groupName"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right" class="grow">
            <v-btn text color="red" @click="close()"> Cancel </v-btn>
          </v-col>
          <v-col align="right" class="shrink">
            <v-btn
              class="msaBlue white--text"
              @click="submit()"
              :disabled="disableSave"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'PrivateDocumentGroupDetail',
  props: {
    group: {
      type: Object,
    },
  },
  computed: {
    disableSave() {
      if (this.isNew) {
        return this.name.trim().length == 0;
      } else {
        return (
          this.name.trim().length == 0 || this.name.trim() == this.group.name
        );
      }
    },
    isNew() {
      return this.group.id == 0;
    },
  },
  data() {
    return {
      name: '',
      dialog: false,
      rules: [(v) => this.$helpers.required(v, 'Folder name')],
      errorMessages: [],
    };
  },
  watch: {
    name(nv) {
      //clear errors for case when the error comes from the server.
      if (nv && nv.length && this.errorMessages.length) {
        this.errorMessages = [];
      }
    },
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.name = this.group.name;
        this.dialog = true;
      });
    },
    close() {
      this.dialog = false;
      this.$emit('closed');
    },
    submit() {
      const url = this.isNew
        ? 'create-private-document-group?format=json'
        : 'edit-private-document-group?format=json';
      const params = {
        name: this.name,
        loaderText: 'Saving...',
        parentPrivateLibraryGroupId: this.group.parentPrivateLibraryGroupId,
        disableAutoError: true,
      };

      if (!this.isNew) {
        params.id = this.group.id;
      }

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('confirmed');
          this.close();
        })
        .catch((e) => {
          this.errorMessages.push(e.response.data.message);
        });
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
