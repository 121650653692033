<template>
  <div>
    <v-dialog v-model="dialog" width="750" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          <v-row>
            <v-col class="grow"> Manage Folders</v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" align="right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-blur
                    class="msaBlue white--text"
                    v-on="on"
                    @click="showNewGroupDetails"
                  >
                    <v-icon> mdi-folder-plus-outline </v-icon>
                  </v-btn>
                </template>
                <span>Add Folder</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                :active="isLoading"
                :indeterminate="true"
              ></v-progress-linear>
              <PrivateDocumentGroupsSimple
                :groups="groups"
                :selected="[]"
                :forUpload="true"
                @showGroupDetails="showGroupDetails"
                @deleteGroup="onGroupDeleted"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PrivateDocumentGroupDetail
      ref="groupDetail"
      :group="selectedGroup"
      @confirmed="saveGroupDetails()"
    />
    <v-btn @click="show" dark color="msaBlue">Manage Folders</v-btn>
  </div>
</template>

<script>
import PrivateDocumentGroupsSimple from '@/components/PrivateDocumentLibrary/PrivateDocumentGroupsSimple.vue';
import PrivateDocumentGroupDetail from '@/components/PrivateDocumentLibrary/PrivateDocumentGroupDetail.vue';

export default {
  name: 'PrivateDocumentLibraryGroupManageDialog',
  components: {
    PrivateDocumentGroupsSimple,
    PrivateDocumentGroupDetail,
  },
  data() {
    return {
      reloadCounter: 0,
      isDirty: false,
      dialog: false,
      groups: [],
      isLoading: false,
      selectedGroup: { name: '', id: 0 },
    };
  },
  methods: {
    onGroupDeleted(id) {
      const params = {
        loaderText: 'Deleting folder...',
        id,
      };
      const url = 'delete-private-document-group?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('groupDelete', id);
          this.loadGroups();
          this.isDirty = true;
        })
        .catch((error) => error);
    },
    loadGroups() {
      this.isLoading = true;
      this.$axios
        .post('get-private-library-groups-for-manage', {})
        .then((response) => {
          this.groups = response.data;
          this.isLoading = false;
        });
    },
    showNewGroupDetails() {
      const newGroup = {
        id: 0,
        level: 1,
        name: '',
        parentPrivateLibraryGroupId: 0,
      };
      this.showGroupDetails(newGroup);
    },
    showGroupDetails(event) {
      this.selectedGroup = event;
      this.$refs.groupDetail.show();
    },
    saveGroupDetails() {
      this.loadGroups();
      this.$emit('folderChange');
    },
    show() {
      this.loadGroups();
      this.dialog = true;
      this.isDirty = false;
    },
    close() {
      this.groups = [];
      this.dialog = false;
      if (this.isDirty) {
        this.$emit('folderChange');
      }
    },
  },
};
</script>

<style></style>
