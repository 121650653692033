<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <SearchBar
          searchLabel="Search by file name..."
          @search="onSearch"
          ref="searchBar"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <PublicDocumentLibraryFilter
          ref="filters"
          :groups="groups"
          :filters="filters"
          @applyFilters="applyFilters"
        />
      </v-col>
      <v-col class="shrink">
        <PublicDocumentLibraryExport :filters="filters" />
      </v-col>
    </v-row>
    <v-row v-if="showNoResultsMessage" justify="center" class="mt-8">
      <span>No results found</span>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels
          v-if="!loading"
          transition="fade-transition"
          v-model="expanded"
          multiple
          active-class="msaBlue white--text"
        >
          <v-expansion-panel
            v-for="(group, index) in groups"
            :key="group.id"
            class="my-2"
          >
            <v-expansion-panel-header
              hide-actions
              v-slot="{ open }"
              :style="headerStyles(index)"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col class="grow pl-4 font-weight-medium">
                  <span>{{ group.name }}</span>
                  <v-badge
                    inline
                    class="ml-2"
                    :bordered="open"
                    :content="group.count || '0'"
                    :value="group.count || '0'"
                  ></v-badge>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <PublicDocumentLibraryDocuments
                :filters="filters"
                :groupId="group.id"
                :selected="selected"
                @onSelect="handleSelection($event)"
                :key="reloadDocuments"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <PublicDocumentLibaraySelectionModal
      :documents="selected"
      v-if="selected.length > 0"
      @onUnselect="handleUnselect($event)"
      @confirmed="onConfirmed"
      @closed="selected = [...$event]"
      @cancel="onCancel"
    />
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import PublicDocumentLibraryDocuments from '@/components/PublicDocumentLibrary/PublicDocumentLibraryDocuments.vue';
import PublicDocumentLibraryFilter from '@/components/Filters/PublicDocumentLibraryFilter.vue';
import PublicDocumentLibaraySelectionModal from '@/components/PublicDocumentLibrary/PublicDocumentLibaraySelectionModal.vue';
import PublicDocumentLibraryExport from '@/components/PublicDocumentLibrary/PublicDocumentLibraryExport.vue';
export default {
  name: 'CompanyResourcesPage',
  components: {
    SearchBar,
    PublicDocumentLibraryDocuments,
    PublicDocumentLibraryFilter,
    PublicDocumentLibaraySelectionModal,
    PublicDocumentLibraryExport,
  },
  data() {
    return {
      expanded: [],
      filters: {
        search: '',
        groupIds: [],
      },
      groups: [],
      selected: [],
      reloadDocuments: 1,
      expandedGroupIds: [],
      loading: false,
    };
  },
  watch: {
    expanded: {
      deep: true,
      handler(newVal, oldVal) {
        // handle case for closing group
        if (newVal.length < oldVal.length) {
          const closedIndex = oldVal.filter((x) => !newVal.includes(x));
          const closedId = this.groups[closedIndex].id;
          const index = this.expandedGroupIds.findIndex((i) => i == closedId);
          this.expandedGroupIds.splice(index, 1);
        }

        // get ids of expanded groups
        this.getExpandedGroupIds();
      },
    },
  },
  computed: {
    headerStyles() {
      return (index) =>
        this.expanded.findIndex((i) => i == index) == -1
          ? {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            }
          : {};
    },
    showNoResultsMessage() {
      if (this.loading) {
        return false;
      }
      return this.groups.length == 0;
    },
  },
  methods: {
    onConfirmed() {
      this.clearSelected();
      this.reloadDocuments++;
    },
    onCancel() {
      this.clearSelected();
    },
    clearSelected() {
      this.selected = [];
    },
    onSearch(data) {
      this.filters.search = data;
      this.$refs.filters.setFilters(this.filters);
      this.applyFilters(this.filters);
    },
    getPublicLibraryGroups() {
      this.loading = true;
      const params = {
        filters: this.filters,
        loaderText: 'Loading...',
      };

      const url = 'get-public-library-groups?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.groups = response.data;
          this.setupExpanded();
          this.loading = false;
        })
        .catch((error) => error);
    },
    // Insure css styles are applied to the correct group when the groups change
    getExpandedGroupIds() {
      this.expanded.forEach((i) => {
        if (this.groups[i]) {
          this.expandedGroupIds.push(this.groups[i].id);
        }
      });
      // Remove duplicates
      this.expandedGroupIds = [...new Set(this.expandedGroupIds)];
    },
    // Insure css styles are applied to the correct group when the groups change
    setupExpanded() {
      this.expanded = [];
      this.expandedGroupIds.forEach((id) => {
        const index = this.groups.findIndex((g) => g.id == id);
        this.expanded.push(index);
      });
    },
    handleSelection(event) {
      if (event.value) {
        this.selected = this.selected
          .concat(event.docs)
          // remove duplicates
          .filter(
            (item, index, self) =>
              self.findIndex((t) => t.id == item.id) == index,
          );
      } else {
        this.selected = this.selected.filter(
          (selected) => !event.docs.some((doc) => doc.id == selected.id),
        );
      }
    },
    handleUnselect(event) {
      this.selected = this.selected.filter(
        (selected) => selected.id != event.id,
      );
    },

    applyFilters(filters) {
      this.filters = filters;
      this.getPublicLibraryGroups();
    },
  },
  mounted() {
    this.getPublicLibraryGroups();
  },
};
</script>

<style></style>
