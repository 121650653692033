<template>
  <v-btn-toggle
    :value="isPublished"
    dense
    :mandatory="isPublished !== null"
    active-class="msaBlue white--text"
    style="width: 100%"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          :value="0"
          v-on="on"
          :style="{ width: '50%' }"
          @click="$emit('click', 0)"
        >
          <v-icon color="orange"> mdi-eye-off </v-icon>
        </v-btn>
      </template>
      <span>Unpublish</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          :value="1"
          v-on="on"
          :style="{ width: '50%' }"
          @click="$emit('click', 1)"
        >
          <v-icon color="green"> mdi-eye </v-icon>
        </v-btn>
      </template>
      <span>Publish</span>
    </v-tooltip>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'PublishButtonGroup',
  props: ['isPublished'],
};
</script>

<style></style>
